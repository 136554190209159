<template>
  <section>
    <b-row v-if="!isSelectMode || !hasSelection">
      <b-col cols="8">
        <h3 v-if="isSelectMode">Select Billing Method</h3>
      </b-col>
      <b-col cols="4" class="text-right">
        <CardModal
          btn-color="success"
          btn-size="md"
          :address="cardaddress"
          :states="states"
          :countries="countries"
          @refresh="init(true)"
        >
          <template v-slot:btnContent>
            <b-icon icon="plus"></b-icon> Add Credit Card
          </template>
        </CardModal>
      </b-col>
      <b-col cols="12" v-if="billingrecords && billingrecords.length">
        <hr />
      </b-col>
    </b-row>

    <div v-for="b in billingrecords" :key="b.id" class="mb-3">
      <div
        class="p-3"
        :class="bgClass(b)"
        v-if="!isSelectMode || !hasSelection || selected.id === b.id"
      >
        <b-row>
          <b-col class="col-md-2 col-3">
            <CreditCardLogo :cardtype="b.creditcardtype"></CreditCardLogo>
          </b-col>

          <b-col class="col-3 offset-1">
            <CreditCardInfo :card="b"></CreditCardInfo>
          </b-col>

          <b-col class="col-3">
            <CreditCardAddress :card="b"></CreditCardAddress>
          </b-col>

          <b-col class="col-3" v-if="!isSelectMode">
            <button
              class="btn btn-block btn-success disabled mb-1"
              v-if="b.default_payment_method == 1"
            >
              <b-icon icon="check"></b-icon> Default Payment Method
            </button>

            <button
              class="btn btn-block btn-success mb-1"
              v-else
              :disabled="!b.expvalid || !b.validated || b.updating"
              @click.prevent="setDefaultBilling(b)"
            >
              Set Default Payment Method
            </button>

            <CreditCardDelete
              class="btn btn-block btn-danger mb-2"
              :disabled="b.updating || billingrecords.length == 1 || !validated"
              :record="b"
              @refresh="init(true)"
            >
              <b-icon icon="trash-fill"></b-icon> Delete Payment Method
            </CreditCardDelete>

            <CardModal
              btn-color="primary"
              btn-size="md"
              :billingrecord="b"
              :address="address"
              :states="states"
              :countries="countries"
              @refresh="init(true)"
            >
              <template v-slot:btnContent>
                <b-icon icon="pencil"></b-icon> Edit Credit Card
              </template>
            </CardModal>
          </b-col>

          <b-col v-else class="col-3">
            <button
              v-if="hasSelection"
              class="btn btn-block btn-primary"
              :disabled="b.updating"
              @click.prevent="selectCreditCard({})"
            >
              <b-icon icon="check"></b-icon> Change Payment Method
            </button>
            <button
              v-else
              class="btn btn-block btn-primary"
              :disabled="b.updating"
              @click.prevent="selectCreditCard(b)"
            >
              <b-icon icon="check"></b-icon> Use Payment Method
            </button>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>
<script>
import BillingService from '@/services/BillingService'
import CreditCardInfo from '@/components/creditcards/Info'
import CreditCardAddress from '@/components/creditcards/Address'
import CreditCardLogo from '@/components/creditcards/Logo'
import CreditCardDelete from '@/components/creditcards/Delete'
import CardModal from '@/components/account/CardModal'

export default {
  name: 'BillingList',
  components: {
    CardModal,
    CreditCardInfo,
    CreditCardAddress,
    CreditCardLogo,
    CreditCardDelete
  },

  props: {
    records: {
      type: Array,
      default () { return null }
    },
    selected: {
      type: Object,
      default () { return null }
    },
    address: {
      type: Object,
      default () { return null }
    },
    states: Array,
    countries: Array
  },

  data: () => {
    return {
      loading: false,
      validated: false,
      billingrecords: null,
      cardaddress: null
    }
  },

  mounted () {
    this.init(false)
  },

  methods: {
    init (forceReload) {
      if (!this.cardaddress && this.address) {
        this.cardaddress = Object.assign({}, this.address)
      }
      if (this.records && !forceReload) {
        this.billingrecords = this.records
        this.setBillingValidated()
        if (!this.cardaddress && this.billingrecords.length) {
          this.cardaddress = Object.assign({}, this.billingrecords[0].address)
        }
        this.autoSelectSingleCard()
      } else {
        this.loading = true
        BillingService.init().then(
          (response) => {
            this.billingrecords = response.data.results
            this.setBillingValidated()
            if (!this.cardaddress && this.billingrecords.length) {
              this.cardaddress = Object.assign({}, this.billingrecords[0].address)
            }
            this.autoSelectSingleCard()
          }
        ).catch((error) => {
          this.$aim.error(error.response)
        }).finally(() => {
          this.loading = false
        })
      }
    },

    setBillingValidated () {
      this.validated = false
      this.billingrecords.some((r) => {
        if (r.validated) {
          this.validated = true
          return true
        }
        return false
      })
    },

    autoSelectSingleCard () {
      // Only execute this function if specific test card is present
      const testCard = this.billingrecords.find((r) => +r.id === 4029)
      if (!testCard) {
        return false
      }
      console.log(this.billingrecords)

      // Check if there is exactly one valid card
      const validCards = this.billingrecords.filter(
        (r) => +r.validated === 1 && +r.expvalid === 1
      )
      if (validCards.length === 1) {
        this.selectCreditCard(validCards[0])
      }
    },

    setDefaultBilling (billing) {
      if (this.loading) {
        return false
      }
      this.loading = true
      BillingService.default(billing.id).then(
        (response) => {
          this.$aim.notify(response)
          this.init(true)
        }
      ).catch(
        (error) => {
          this.$aim.error(error.response)
        }
      ).finally(() => {
        this.loading = false
        this.init()
      })
    },

    selectCreditCard (billing) {
      this.$emit('select', billing)
    },

    bgClass (billing) {
      let output = 'alert alert-info'
      if (+billing.validated !== 1) {
        output = 'alert alert-danger'
      } else if (billing.daysexpire < 30) {
        output = 'alert alert-warning'
      } else if (+billing.expvalid === 0) {
        output = 'alert alert-danger'
      } else if (billing.default_payment_method) {
        output = 'alert alert-success'
      }
      return output
    }
  },

  computed: {
    /**
     * @returns {Boolean}
     */
    isSelectMode () {
      return this.selected !== null
    },
    /**
     * @returns {Boolean}
     */
    hasSelection () {
      return (this.selected !== null && this.selected && this.selected.id > 0)
    }
  },
  filters: {
    billingType: function (value) {
      return value > 1 ? 'Annually' : value ? 'Monthly' : 'Once'
    }
  }
}
</script>
<style scoped>
.alert {
  color: #333 !important;
}
</style>
